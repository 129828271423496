import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container, Grid, Input, Button } from '@material-ui/core'
import {
  CollectionsBookmark,
  ColorLens,
  Public,
  Business,
  Eco,
  Computer,
  LocalHospital,
  Build,
  Work,
  Search
} from '@material-ui/icons'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CourseWrapper from '../../components/CourseWrapper'
import logoABED from '../../images/logo-abed.png'
import CardCordenation from '../../components/CardCordenation'
import { PageTitle } from '../../components/PageTitle'
import depoimento1 from '../../images/depoimentos/Datanara.jpg'

import tereza from '../../images/tereza.jpeg'
import bete from '../../images/bete.png'
import zaira from '../../images/zaira.jpeg'
import luzia from '../../images/luzia.png'
import almir from '../../images/almir.png'
import adenilda from '../../images/adenilda.jpg'
import alaide from '../../images/alaide.jpg'
import valmir from '../../images/valmir.png'
import {
  SectionTitle,
  FilterButton,
  SearchBar,
} from '../../styles/pages/presencial'

export default function PosDireitoPage(props) {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  const [filteredCourses, setFilteredCourses] = useState(edges)
  const [query, setQuery] = useState('');
  const [inputv, setInputv] = useState('');
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    const posts = filteredCourses.filter(({ node }) =>
      node.frontmatter.name.toLowerCase().includes('direito'.normalize("NFC").toLowerCase())
    )
    setSearchResults(posts)
  }, ['direito'.toLowerCase()])

  const filterCourses = filter => {
    switch (filter) {
      case 'educacao':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('educacao'))
        )
        break
      case 'artes':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('artes'))
        )
        break
      case 'jornalismo':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('jornalismo'))
        )
        break
      case 'negocios':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('negocios'))
        )
        break
      case 'computacao':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('computacao'))
        )
        break
      case 'engenharia':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('engenharia'))
        )
        break
      case 'agricultura':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('agricultura'))
        )
        break
      case 'saude':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('saude'))
        )
        break
      case 'direito':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('direito'))
        )
        break
      case 'servicos':
        setSearchResults(
          edges.filter(({ node }) => node.frontmatter.area.includes('servicos'))
        )
        break
    }
  }

  return (
    <Layout>
      <SEO title="Pós-Graduação EaD" />

      <Container style={{ marginTop: 70 }}>
        <PageTitle>Pós-Graduação EaD</PageTitle>
        <SearchBar>
          <Input
            placeholder="Pesquisar curso"
            value={inputv}
            onChange={e => setInputv(e.target.value)}
            style={{ padding: 6, borderRadius: 5, border: '2px solid' }}
          />
          <Button

            onClick={(event) => {
              setQuery(inputv)
              document.body.scrollTop = 900;
            }}>
            Pesquisar
          </Button>
        </SearchBar>
        <p
          style={{
            marginTop: 50,
            fontSize: 18,
            color: '#333',
            textAlign: 'justify',
            lineHeight: '1.8em',
            wordSpacing: 8,
          }}
        >
          Na Pós-Graduação EaD, as atividades virtuais (videoaula, e-book, chats,
          fóruns, etc.) ficam a todo tempo disponíveis aos alunos mediante acesso
          ao AVA* – Ambiente Virtual de Aprendizagem da Faculdade Jardins.
          <br /> <br />
          <span style={{ fontSize: 15, color: '#555' }}>
            *AVA – Ambiente Virtual de Aprendizagem da Faculdade Jardins é uma
            Plataforma de Aprendizagem on-line completa para otimizar a formação
            de nossos alunos, dispondo, inclusive, de vídeos aula, e-books,
            biblioteca virtual, periódicos virtuais, materiais de aula dos docentes
            e tutores, slides, artigos, textos, hipertextos, links, chats, fóruns,
            exercícios, avaliações, etc.
          </span>
        </p>
        <img src={logoABED} alt="logoABED" width="90" style={{ margin: '10px' }} />

      </Container>

      <Container disableGutters={true} style={{ marginTop: 60 }}>
        <SectionTitle>Cursos de Pós-Graduação</SectionTitle>

        <Grid container spacing={2} style={{ marginTop: 10 }}>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FilterButton
              variant="contained"
              startIcon={<Business />}
              onClick={() => filterCourses('direito')}
              style={{ width: '100%' }}
            >
              Direito
            </FilterButton>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FilterButton
                variant="contained"
                startIcon={<CollectionsBookmark />}
                onClick={() => filterCourses('educacao')}
              >
                Educação
              </FilterButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FilterButton
                variant="contained"
                startIcon={<Business />}
                onClick={() => filterCourses('negocios')}
              >
                Negócios e Administração
              </FilterButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FilterButton
                variant="contained"
                startIcon={<Eco />}
                onClick={() => filterCourses('agricultura')}
              >
                Agricultura, Silvicultura, Pesca e Veterinária
              </FilterButton>
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={6} md={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FilterButton
                variant="contained"
                startIcon={<ColorLens />}
                onClick={() => filterCourses('artes')}
              >
                Artes e Humanidade
              </FilterButton>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FilterButton
                variant="contained"
                startIcon={<Computer />}
                onClick={() => filterCourses('computacao')}
              >
                Computação e Tecnologias da Informação (TIC)
              </FilterButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FilterButton
                variant="contained"
                startIcon={<LocalHospital />}
                onClick={() => filterCourses('saude')}
              >
                Saúde e Bem-estar
              </FilterButton>
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={6} md={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FilterButton
                variant="contained"
                startIcon={<Public />}
                onClick={() => filterCourses('jornalismo')}
              >
                Ciências Sociais, Jornalismo e Informação
              </FilterButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FilterButton
                variant="contained"
                startIcon={<Build />}
                onClick={() => filterCourses('engenharia')}
              >
                Engenharia, Produção e Construção
              </FilterButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FilterButton
                variant="contained"
                startIcon={<Work />}
                onClick={() => filterCourses('servicos')}
              >
                Serviços
              </FilterButton>
            </Grid>
          </Grid>
        </Grid>



        <div>
          {searchResults.map(({ node }) => (
            <CourseWrapper
              id="courses"
              name={node.frontmatter.name}
              duration={node.frontmatter.duration}
              price={node.frontmatter.price}
              parcel={node.frontmatter.parcelas}
              link={node.fields.slug}
              key={node.frontmatter.price}
              graduation={node.frontmatter.category}
            />
          ))}
        </div>
      </Container>
      <Container style={{ marginTop: 40 }}>
        <h4 style={{ textAlign: 'center' }}>CONHEÇA ALGUNS DE NOSSOS COORDENADORES</h4>
        <Grid container spacing={2} direction="row">
          {/* <Grid xs={12} item sm={12} md={6} lg={6} spacing={2}> */}
          <CardCordenation
            title="Professora Bete"
            description={<p>Enfermeira. Doutoranda em educação pela UFS.<br></br> Mestra em Enfermagem pela EEAN/ UFRJ. <br></br> Servidora do HU/ Sergipe. Coordenadora dos Cursos de graduação e pós graduação da Faculdade Jardins.</p>}
            image={bete}
          />
          <CardCordenation
            title="Profa. Dra. Záira Moura"
            description={<p>Doutora em Ciências da Saúde, Enfermeira da Secretaria de Saúde do Estado de Sergipe.<br></br>http://lattes.cnpq.br/6177265138676197</p>}
            image={zaira}
          />
          <CardCordenation
            title="Prof. Dr. Valmir Martins"
            description="Pós Doutor em Democracia e Direitos Humanos, Mestre e Doutor na área de Administração. Profissional com experiência de 30 anos como Oficial Militar, gestor de empresas educacionais, professor e consultor em Direito Educacional, empresário e pesquisador.
            CV: http://lattes.cnpq.br/5995246863621264"
            image={valmir}
          />
          <CardCordenation
            title="Profa. Dra. Alaíde Barbosa"
            description="Pós Doutora em Engenharia pela Poli USP, Engenheira, Administradora, Analista de Computação e Química. Profissional com experiência como Diretora Executiva em Empresas Multinacionais, Empresária e Educadora.
            CV: http://lattes.cnpq.br/6685382103923859"
            image={alaide}
          />
          <CardCordenation
            title="Profa. Ma. Adenilda Couto"
            description="Mestra em Planejamento Territorial e Desenvolvimento Social, Assistente Social. Administradora e Licenciada em Pedagogia, profissional com vasta experiência em gestão, coordenação na área acadêmica.
            CV: http://lattes.cnpq.br/600223525395733"
            image={adenilda}
          />
          <CardCordenation
            title="Prof. Me. Almir Martins"
            description="Mestre em Planejamento Territorial e Desenvolvimento Social. Administrador com experiência acadêmica e em grandes empresas.
            CV: http://lattes.cnpq.br/0291039112670467"
            image={almir}
          />
          <CardCordenation
            title="Profa. Ma. Luzia Barreto"
            description="Mestra, com graduações em Engenharia, Licenciatura em Ciências Biológicas e Pedagogia. Profissional com experiência em EAD, consultoria, pesquisa e educadora.
            CV: http://lattes.cnpq.br/3932085275496056"
            image={luzia}
          />
          <CardCordenation
            title="Dra. Tereza"
            description="Odontóloga. Mestra em Odontologia pelo CPO São Leopoldo Mandic (Unicamp), Especialista em Ortodontia pelo CPOSLM, Especialista em Odontologia do Trabalho pelo CPOSLM. Ortodontista da Clínica Ortopronto e do Centro de Ortodontia da Odonto System."
            image={tereza}
          />

          {/* </Grid> */}
        </Grid>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___name], order: ASC }
      filter: { frontmatter: { category: { eq: "pos-graduacao ead" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            area
            category
            price
            duration
            parcelas
          }
        }
      }
    }
  }
`
